import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Hidden,
  Typography
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  PaymentSummaryGrandTotalRow, PaymentSummaryRow, PaymentSummaryRowBoldTitle, PayNowButton,
  PaymentSummaryRowTitle, PaymentSummaryCardHeader, PaymentSummaryCardHeaderTitle, ContinueButton,
  TipTool, ButtonWrapper,
  RfqContainer,
  TestWrapper,
  CheckImg,
  Info,
  ViewItem,
  AlertBox, RedInfoIcon,
  AlertHeader,
  AlertText
} from './paymentSummary.styles';
import currencyFormatter from '../../shared/currencyFormatter';
import ServiceFeePopper from './serviceFeePopper';

const PaymentSummary = ({
  cart, orderTotal, serviceFee, deliveryFee, updateLoading, grandTotal, promo,
  placeLoading, platform, handleDirectPlaceOrder, cartRfqTotalCount, savings, walletBallance
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [action, setAction] = useState(null);

  const handleOpenTip = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const totalPromo = promo;
  const isPrevRoute = state?.prevLocation?.startsWith('/new-order');
  const otherRoute = platform === 'erp' ? '/inventory' : '/new-order';
  const route = isPrevRoute ? -1 : otherRoute;

  const creditLimitExceeded = (grandTotal - totalPromo) > walletBallance;

  return (
    <>
      <PaymentSummaryCardHeader>
        <PaymentSummaryCardHeaderTitle>Payment Summary</PaymentSummaryCardHeaderTitle>
      </PaymentSummaryCardHeader>
      {creditLimitExceeded && (
        <AlertBox icon={<RedInfoIcon />} severity="error" variant="filled">
          <AlertHeader fontWeight="bold">Credit Limit Exceeded!</AlertHeader>
          <AlertText>Your order exceeds your credit limit. You’ll need to adjust your order to proceed.</AlertText>
        </AlertBox>
      )}
      <PaymentSummaryRow>
        <PaymentSummaryRowTitle>
          Order Total
        </PaymentSummaryRowTitle>
        <PaymentSummaryRowBoldTitle>
          {`₦ ${currencyFormatter(orderTotal || 0)}`}
        </PaymentSummaryRowBoldTitle>
      </PaymentSummaryRow>
      <PaymentSummaryRow>
        <PaymentSummaryRowTitle>
          Delivery Fee
        </PaymentSummaryRowTitle>
        <PaymentSummaryRowBoldTitle>
          {`₦ ${currencyFormatter(deliveryFee || 0)}`}
        </PaymentSummaryRowBoldTitle>
      </PaymentSummaryRow>
      <PaymentSummaryRow>
        <PaymentSummaryRowTitle>
          Service Charge
          {/* <TipTool tabIndex={0} style={{ marginLeft: '1rem', cursor: 'pointer' }} onClick={(event) => handleOpenTip(event)}>ⓘ</TipTool> */}
        </PaymentSummaryRowTitle>
        <PaymentSummaryRowBoldTitle>
          {`₦ ${currencyFormatter(serviceFee || 0)}`}
        </PaymentSummaryRowBoldTitle>
      </PaymentSummaryRow>
      <PaymentSummaryRow>
        <PaymentSummaryRowTitle>
          Total Savings
        </PaymentSummaryRowTitle>
        <PaymentSummaryRowBoldTitle>
          {`₦ ${currencyFormatter(+savings || 0)}`}
        </PaymentSummaryRowBoldTitle>
      </PaymentSummaryRow>
      <PaymentSummaryGrandTotalRow>
        <PaymentSummaryRowBoldTitle>
          Grand Total
        </PaymentSummaryRowBoldTitle>
        <PaymentSummaryRowBoldTitle>
          {`₦ ${currencyFormatter((grandTotal - totalPromo) || 0)}`}
        </PaymentSummaryRowBoldTitle>
      </PaymentSummaryGrandTotalRow>

      <RfqContainer>
        <TestWrapper>
          <CheckImg />
          <Info>No. of Items in Quote Request</Info>
        </TestWrapper>
        <ViewItem>
          {cartRfqTotalCount}
        </ViewItem>
      </RfqContainer>
      <Hidden mdDown>
        <PayNowButton
          variant="contained"
          fullWidth
          onClick={() => handleDirectPlaceOrder()}
          disabled={placeLoading || updateLoading || !cart.length || creditLimitExceeded}
          className="mpAdmin-uat-cartPage-placeOrder"
        >
          Place Order
        </PayNowButton>
        <ContinueButton
          fullWidth
          onClick={() => navigate(route)}
          className="mpAdmin-uat-cartPage-continueShopping"
        >
          Continue Shopping
        </ContinueButton>
      </Hidden>

      <Hidden mdUp>
        <ButtonWrapper>
          <PayNowButton
            variant="contained"
            fullWidth
            onClick={() => handleDirectPlaceOrder()}
            disabled={placeLoading || updateLoading || !cart.length || creditLimitExceeded}
            className="mpAdmin-uat-cartPage-placeOrder"
          >
            Place Order
          </PayNowButton>
          <ContinueButton
            fullWidth
            onClick={() => navigate(route)}
            className="mpAdmin-uat-cartPage-continueShopping"
          >
            Continue Shopping
          </ContinueButton>
        </ButtonWrapper>
      </Hidden>

      <ServiceFeePopper action={action} handleOpenTip={handleOpenTip} />
    </>
  );
};

PaymentSummary.propTypes = {
  cart: PropTypes.instanceOf(Array),
  updateLoading: PropTypes.bool.isRequired,
  handleDirectPlaceOrder: PropTypes.func.isRequired,
  orderTotal: PropTypes.number,
  serviceFee: PropTypes.number,
  deliveryFee: PropTypes.number,
  grandTotal: PropTypes.number,
  promo: PropTypes.number,
  placeLoading: PropTypes.bool.isRequired,
  platform: PropTypes.string.isRequired,
  cartRfqTotalCount: PropTypes.number,
  savings: PropTypes.number
};

PaymentSummary.defaultProps = {
  cart: [],
  orderTotal: 0,
  serviceFee: 0,
  deliveryFee: 0,
  grandTotal: 0,
  promo: 0,
  cartRfqTotalCount: 0,
  savings: 0
};

export default PaymentSummary;
